<script>
// import Layout from "../../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
 import { required ,maxLength} from "vuelidate/lib/validators";
// import { fetchSettings } from "../api";
// import infoStaff from './infoStaff.vue';
// import Infrastructure from './infrastructure.vue';
// import Multiselect from 'vue-multiselect'
  import Swal from "sweetalert2";

export default {

    page: {
        title: "Chambre",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: { },
    props:{
      selectedRow : null,
     room: {
      type: Object,
      default: null,
    }
    },
  data() {
    return {
      title: "Nouveau Type de chambre",
      typeForm: {
        designation: "",
        type: "hosting_room_classification",
      },
       classifications: [
        {
          id: 'vip',
          name: 'VIP',
        },
        {
          id: 'ordinary',
          name: 'Ordinnaire',
        },
      ],
      userStructuresTypes: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      
    };
  },
  validations: {
    typeForm: {
      designation: { required, maxLength: maxLength(64) },
    },
  },

  watch:{
    selectedRow(val){
      this.clearFrom();
      if(val.length > 0 && undefined != val[0]){
        this.typeForm.designation = val[0].designation;
        this.typeForm.uuid        = val[0].uuid ;
      }
    }  
  },
  mounted(){

},

 methods:{
    clearFrom(){
      this.typeForm= {
        designation: "",
        type: "hosting_room_classification",
      }
    },
     formSubmit() {
       
      this.submitted = true;
      this.$v.$touch();
      var valid = this.$v.typeForm.$invalid;
      // var validFormData = this._validateContractAmnts();

      if(!valid){
      this.$http
        .post("/infrastructure/room_types/store", this.typeForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              Swal.fire("Félicitations!", res.data.original.msg, "success");   
              this.$v.$reset();
              this.$emit('realodTable',true) 
              this.clearFrom() ;         
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
      }

    },
  }
    
}
</script>

<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="formrow-email-input">Désignation *</label>
                    <input
                      id="projectname"
                      v-model="typeForm.designation"
                      type="text"
                      class="form-control"
                      required
                      placeholder=""
                      :class="{'is-invalid':$v.typeForm.designation.$error}"
                    />
                  </div>
                </div>

                <!-- <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="formrow-email-input">Classification *</label>
                    <select class="form-control" v-model="typeForm.classification" required>
                      <option v-for="classification in classifications" v-bind:value="classification.id" v-bind:key="classification.id">{{ classification.name }}</option>
                    </select>
                   
                   
                  </div>
                </div> -->

                <!-- <div class="col-12">
                  <div class="form-group">
                    <label for="formrow-email-input">Description</label>
                    <input
                      id="projectname"
                      v-model="typeForm.description"
                      type="text"
                      class="form-control"
                      
                      placeholder=""
                    />
                  </div>
                </div> -->
              </div>

              <div class="row">
                <div v-if="typeForm.uuid" class="col text-right">
                  <button
                    :disabled="$v.typeForm.$invalid"
                    class="btn btn-warning"
                    type="submit"
                  >
                    Modifier
                  </button>
                </div>
                <div v-else class="col text-right">
                  <button
                    :disabled="$v.typeForm.$invalid"
                    class="btn btn-primary"
                    type="button" @click="formSubmit"
                  >
                    Enregistrer
                  </button>
                </div>
                
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- </Layout> -->
</template>
